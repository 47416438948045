import React from 'react';
import {
	PersonalEconomy,
	Case,
	LPEmploymentEnum,
} from '../../../../models/SelmaModels';
import { Applicant } from '../../../../models/selmamodels/Applicant';
import { AppComponent } from '../../../AppComponent';
import { FormUnit, FormUnitSize, FormUnitPadding } from '../../../layout/FormUnit/FormUnit';
import { Label } from '../../../widget/display/Label/Label';
import { Textbox, TextboxType } from '../../../widget/input/Textbox/Textbox';
import { ApplicantService } from '../../../../services/ApplicantService';
import { Money } from '../../../widget/input/Money/Money';
import { SelectMonth } from '../../../widget/input/SelectMonth/SelectMonth';
import { NumberFormatValues } from 'react-number-format';
import { PersonalEconomyService } from '../../../../services/PersonalEconomyService';
import { SelectOption, Select } from '../../../widget/input/Select/Select';
import { THtml } from '../../../widget/display/THtml';
import { uiEmploymentType } from '../../../../models/UiModels';
import { isNumber } from 'lodash';
import { Validation } from '../../../widget/display/Validation/Validation';
import { Checkbox } from '../../../widget/input/Checkbox/Checkbox';
import { Help } from '../../../widget/display/Help/Help';
import { TypeOfEmployeement } from '../../../../libs/SMELPOClient';

interface Props {
	case: Case;
	applicant: Applicant;
	personalEconomy: PersonalEconomy;
}

interface State {
	addIncomeOfEmployment: boolean | undefined;
}

export class ApplicantIncome extends AppComponent<Props, State> {
	applicantService: ApplicantService;
	personalEconomyService: PersonalEconomyService;
	typeOfEmploymentOptions: SelectOption[];

	constructor(props: Props) {
		super(props);
		this.observeUpdate(this.props.case);
		this.observeUpdate(this.props.applicant);
		this.observeUpdate(this.props.personalEconomy);

		this.applicantService = this.application.services.applicantService;
		this.personalEconomyService = this.application.services.personalEconomyService;

		this.typeOfEmploymentOptions = uiEmploymentType.map((x) => {
			return {
				value: x.key,
				label: x.name,
			};
		});

		const pe = this.props.personalEconomy;
		const addIncomeOfEmployment =
			this.personalEconomyService.mayHaveIncomeOfEmployment(pe)
			&& this.personalEconomyService.hasIncome(pe);
		this.state = { addIncomeOfEmployment: addIncomeOfEmployment }
	}

	typeOfEmploymentChanged = (value: string): void => {
		let val: LPEmploymentEnum | undefined;
		if (value && value !== '') val = LPEmploymentEnum[value];
		const personalEconomy = this.props.personalEconomy;
		this.personalEconomyService.setTypeOfEmployment(this.props.case, personalEconomy, val);
		this.setState({
			addIncomeOfEmployment:
				this.personalEconomyService.mayHaveIncomeOfEmployment(personalEconomy)
				&& (this.personalEconomyService.hasIncome(personalEconomy)
					|| this.state.addIncomeOfEmployment
				)
		});
	};

	incomePerMonthChanged = (values: NumberFormatValues): void => {
		let value: number | undefined;
		if (isNumber(values.floatValue)) {
			value = values.floatValue;
		}
		const personalEconomy = this.props.personalEconomy;
		personalEconomy.income = value;
		this.personalEconomyService.update(personalEconomy);
	};

	employerChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		var val = event.target.value;
		const personalEconomy = this.props.personalEconomy;
		personalEconomy.employer = val;
		this.personalEconomyService.update(personalEconomy);
	};

	yearOfEmploymentChanged = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		const personalEconomy = this.props.personalEconomy;
		const pe = personalEconomy;
		if (!pe) return;

		var year = parseInt(event.target.value);
		if (isNaN(year)) {
			this.personalEconomyService.setYearOfEmployment(pe, undefined);
			return;
		}

		let thisYear = new Date().getFullYear();

		let setYear = year;
		let currentYear = pe.yearOfEmployment ? pe.yearOfEmployment : 0;
		if (currentYear == 0 && year <= 0) {
			setYear = thisYear + year;
		}
		if (setYear > thisYear) {
			setYear = thisYear;
		}

		this.personalEconomyService.setYearOfEmployment(pe, setYear);
	};

	yearOfEmploymentBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
		const personalEconomy = this.props.personalEconomy;
		if (!personalEconomy) return;

		var year = parseInt(event.target.value);
		if (isNaN(year)) return;

		// Be kind to the user. Convert 17-->2017, 98-->1998.
		if (year >= 0 && year < 100) {
			let thisYear = new Date().getFullYear();
			let modYear = thisYear % 100;
			let newYear = year;
			if (year <= modYear) {
				newYear = thisYear - modYear + year;
			} else {
				newYear = thisYear - modYear + year - 100;
			}

			if (newYear !== year) {
				this.personalEconomyService.setYearOfEmployment(personalEconomy, newYear);
			}
		}

	};

	monthOfEmploymentChanged = (month: number): void => {
		const personalEconomy = this.props.personalEconomy;
		personalEconomy.monthOfEmployment = month;
		this.personalEconomyService.update(personalEconomy);
	};

	addIncomeOfEmploymentChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const checked = event.target.checked;
		this.setState({ addIncomeOfEmployment: checked });
		const personalEconomy = this.props.personalEconomy;
		if (!checked) {
			this.personalEconomyService.clearIncomeOfEmployment(personalEconomy);
		}
	}

	render() {
		const personalEconomy = this.props.personalEconomy;
		const pe: PersonalEconomy = personalEconomy;

		const personalEconomyService = this.personalEconomyService;
		const needsIncomeOfEmployment = personalEconomyService.needsIncomeOfEmployment(pe);
		const mayHaveIncomeOfEmployment = personalEconomyService.mayHaveIncomeOfEmployment(pe);

		const addIncomeOfEmployment = this.state.addIncomeOfEmployment;
		const showIncomeOfEmployment = needsIncomeOfEmployment || mayHaveIncomeOfEmployment;

		const needsEmployerInformation = personalEconomyService.needsEmployerInformation(pe);
		const needsMonthOfEmployment = needsEmployerInformation && personalEconomyService.needsMonthOfEmployment(pe);

		const yearOfEmployment = pe.yearOfEmployment
			? pe.yearOfEmployment.toString()
			: '';

		const typeOfEmployment = pe.typeOfEmployment;
		const uiTypeOfEmployment = uiEmploymentType.find(
			(x) => x.type == pe.typeOfEmployment
		);
		const typeOfEmploymentValue: string = uiTypeOfEmployment
			? uiTypeOfEmployment.key
			: '';

		const uq = personalEconomy.customerId + '_';

		return (
			<div className="ApplicantIncome">
				<FormUnit>
					<Label for={uq + "typeOfEmployment"} labelTextkey="Applicant_Income" required={true} />
					<p>
						<THtml k="Applicant_Income_Source" />
					</p>
					<Validation
						forObject={pe}
						errors={[
							{
								validator: personalEconomyService.hasTypeOfEmployment,
								textkey: 'Error_SelectValue',
							},
						]}>
						<Select
							id={uq + "typeOfEmployment"}
							options={this.typeOfEmploymentOptions}
							value={typeOfEmploymentValue}
							handleChange={this.typeOfEmploymentChanged}
							placeholderTextkey="Applicant_Income_Source"
							noSelectableOptionsTextkey="Select_NoOptions"
							isSearchable={true}
						/>
					</Validation>
				</FormUnit>
				{showIncomeOfEmployment && typeOfEmployment !== LPEmploymentEnum.SELF_EMPLOYED && (
					<>
						<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Small} >
							{needsEmployerInformation &&
								<Label for={uq + "income"} labelTextkey="Applicant_IncomeAmount" required={true} />
							}
							{!needsEmployerInformation &&
								<Label for={uq + "income"} labelTextkey="Applicant_IncomeAmountMonth" required={true} />
							}
							{/* <p>
							<THtml k="Applicant_IncomeAmount_Description" />
						</p> */}
							<FormUnit size={FormUnitSize.XSmall} padding={FormUnitPadding.None}>
								<Validation
									forObject={pe}
									errors={[
										{
											validator: personalEconomyService.hasIncome,
											textkey: 'Error_EnterValue',
										},
									]}>
									<Money
										id={uq + "income"}
										value={pe.income}
										suffix={' kr/månad'}
										handleChange={this.incomePerMonthChanged}
										min={0}
									/>
								</Validation>
							</FormUnit>
						</FormUnit>
					</>
				)}
				{needsEmployerInformation && (
					<>
						<FormUnit>
							{typeOfEmployment !== LPEmploymentEnum.SELF_EMPLOYED && <Label for={uq + "employer"} labelTextkey="Applicant_Employer" required={true} />}
							{typeOfEmployment === LPEmploymentEnum.SELF_EMPLOYED && <Label for={uq + "employer"} labelTextkey="Applicant_MyCompany" required={true} />}
							<Validation
								forObject={pe}
								errors={[
									{
										validator: personalEconomyService.hasEmployer,
										textkey: 'Error_EnterValue',
									},
								]}>
								<Textbox
									id={uq + "employer"}
									value={pe.employer || ''}
									handleChange={this.employerChanged}
									maxlength={100}
								/>
							</Validation>
						</FormUnit>
						<FormUnit size={FormUnitSize.Small}>
							{typeOfEmployment !== LPEmploymentEnum.SELF_EMPLOYED && <Label
								for={uq + "employmentYear"} required={true}
								labelTextkey="Applicant_EmploymentYear"
							/>}
							{typeOfEmployment === LPEmploymentEnum.SELF_EMPLOYED && <Label
								for={uq + "employmentYear"} required={true}
								labelTextkey="Applicant_MyCompanyYear"
							/>}
							<Validation
								forObject={pe}
								errors={[
									{
										validator: personalEconomyService.hasEmploymentYear,
										textkey: 'Error_EnterValue',
									},
								]}>
								<Textbox
									id={uq + "employmentYear"}
									type={TextboxType.number}
									value={yearOfEmployment}
									handleChange={this.yearOfEmploymentChanged}
									handleBlur={this.yearOfEmploymentBlur}
								/>
							</Validation>
						</FormUnit>
					</>
				)}
				{needsMonthOfEmployment && (
					<FormUnit size={FormUnitSize.Small}>
						{typeOfEmployment !== LPEmploymentEnum.SELF_EMPLOYED && <Label
							for={uq + "employmentMonth"} required={true}
							labelTextkey="Applicant_EmploymentMonth"
						/>}
						{typeOfEmployment === LPEmploymentEnum.SELF_EMPLOYED && <Label
							for={uq + "employmentMonth"} required={true}
							labelTextkey="Applicant_MyCompanyMonth"
						/>}
						<Validation
							forObject={pe}
							errors={[
								{
									validator: personalEconomyService.hasEmploymentMonth,
									textkey: 'Error_SelectValue',
								},
							]}>
							<SelectMonth
								id={uq + "employmentMonth"}
								value={pe.monthOfEmployment}
								handleChange={this.monthOfEmploymentChanged}
							/>
						</Validation>
					</FormUnit>
				)}
				{showIncomeOfEmployment && typeOfEmployment === LPEmploymentEnum.SELF_EMPLOYED && (
					<>
						<FormUnit size={FormUnitSize.Large} padding={FormUnitPadding.Small} >
							<p>
								<THtml k="Applicant_MyCompany_IncomeAmount_Description" />
							</p>
							{mayHaveIncomeOfEmployment &&
								<FormUnit>
									<Checkbox checked={addIncomeOfEmployment} handleChange={this.addIncomeOfEmploymentChanged} labelTextkey="Applicant_HasIncomeOfEmployment" />
								</FormUnit>
							}
							{addIncomeOfEmployment &&
								<FormUnit size={FormUnitSize.XSmall} padding={FormUnitPadding.Small}>
									<Label for={uq + "income"} labelTextkey="Applicant_MyCompany_IncomeAmount" />
									<Validation
										forObject={pe}
										errors={[
											{
												validator: personalEconomyService.hasIncome,
												textkey: 'Error_EnterValue',
											},
										]}>
										<Money
											id={uq + "income"}
											value={pe.income}
											suffix={' kr/månad'}
											handleChange={this.incomePerMonthChanged}
											min={0}
										/>
									</Validation>
								</FormUnit>
							}
						</FormUnit>
					</>
				)}
			</div>
		);
	}
}
