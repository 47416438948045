import React from 'react';
import { Applicant } from '../../../../models/SelmaModels';
import { AppComponent } from '../../../AppComponent';
import {
	CheckboxGroup,
	CheckboxOption,
} from '../../../widget/input/CheckboxGroup/CheckboxGroup';
import { isNumber } from 'lodash';
import { RadiobuttonGroup, RadioOption } from '../../../widget/input/RadiobuttonGroup/RadiobuttonGroup';

interface Props {
	applicants: Applicant[];
	selectedCustomerIds: string[];
	max?: number;
	hasErrors?: boolean;
	touched?: boolean;
	dirty?: boolean;
	handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	selectionChanged: (value: string[]) => void;
	autoSelectIfSingleApplicant?: boolean;
}

export class ApplicantSelect extends AppComponent<Props> {
	uq: string;
	constructor(props: Props) {
		super(props);
		this.uq = this.application.services.idService.newIdString();
	}

	componentDidMount() {
		this.triggerAutoSelectIfSingleApplicant();
	}

	componentDidUpdate() {
		this.triggerAutoSelectIfSingleApplicant();
	}

	triggerAutoSelectIfSingleApplicant = () => {
		if (this.props.autoSelectIfSingleApplicant) {
			const isSingleApplicant = this.props.applicants.length === 1;
			const hasPreviousSelection = this.props.selectedCustomerIds.length;

			if (isSingleApplicant && !hasPreviousSelection) {
				this.props.selectionChanged([this.props.applicants[0].customerId]);
			}
		}
	};

	handleChange = (values: string[]) => {
		this.props.selectionChanged(values);
	};

	handleSingleChange = (value: string) => {
		this.props.selectionChanged([value]);
	};

	isSelected = (applicant: Applicant, customerIds: string[]): boolean => {
		const applicantCustomerId = applicant.customerId;
		const arr = customerIds;
		const len = arr.length;
		for (var i = 0; i < len; i++) {
			let customerId = arr[i];
			if (customerId === applicantCustomerId) return true;
		}
		return false;
	};

	render() {
		const applicantService = this.application.services.applicantService;
		let values: string[] = this.props.selectedCustomerIds;

		let selectedCount = 0;
		let options: CheckboxOption[] = this.props.applicants.map((x) => {
			let selected = this.isSelected(x, this.props.selectedCustomerIds);
			if (selected) {
				selectedCount++;
			}
			return {
				label: applicantService.getPersonFullName(x),
				value: x.customerId,
				enabled: true,
				checked: selected,
			};
		});

		let max = this.props.max;
		if (max && isNumber(max)) {
			if (selectedCount >= max) {
				options.forEach((x) => {
					x.enabled = x.checked; // disallow more checks.
				});
			}
		}

		let radioOptions: RadioOption[] = [];
		if (max === 1) {
			radioOptions = this.props.applicants.map((x) => {
				let selected = this.isSelected(x, this.props.selectedCustomerIds);
				if (selected) {
					selectedCount++;
				}
				return {
					label: applicantService.getPersonFullName(x),
					value: x.customerId,
					enabled: true,
					checked: selected,
					group: 'ApplicantSelect_' + this.uq
				};
			});
		}

		return (
			<>
				{max !== 1 &&
					<CheckboxGroup
						options={options}
						value={values}
						handleChange={this.handleChange}
						handleBlur={this.props.handleBlur}
						orientation="vertical"
						hasErrors={this.props.hasErrors}
						touched={this.props.touched}
						dirty={this.props.dirty}
					/>
				}
				{max === 1 &&
					<RadiobuttonGroup
						options={radioOptions}
						value={values && values.length ? values[0] : undefined}
						handleChange={this.handleSingleChange}
						handleBlur={this.props.handleBlur}
						orientation="vertical"
						hasErrors={this.props.hasErrors}
						touched={this.props.touched}
						dirty={this.props.dirty}
					/>
				}
			</>
		);
	}
}
