import { BaseService } from './BaseService';
import { Case, Applicant, } from '../models/SelmaModels';
import { SMELPOService } from './SMELPOService';
import { StateService } from './StateService';
import { GuardService } from './GuardService';
import { ValidationResult } from '../utils/ValidationResult';
import { ApplicantService } from './ApplicantService';
import { IdService } from './IdService';
import { UpdateProcessStatusCaseIdStatusEnum, CaseIdStatus, LinksAllFromJSON, StakeholderType, CustomerIdType } from '../libs/SMELPOClient';
import { Login, SessionService } from './SessionService';
import { SmeModelConverter } from '../models/SmeModelConverter';
import { LpoModelConverter } from '../models/LpoModelConverter';
import { ConfigurationService } from './ConfigurationService';
import { AdminCasesSearch } from '../models/extendedlpo/Admin';
import { AttachmentService } from './AttachmentService';
import KycService from './KycService';

export class CaseService extends BaseService {

	private stateService: StateService;
	private smelpoService: SMELPOService;
	private guardService: GuardService;
	private applicantService: ApplicantService;
	public attachmentService?: AttachmentService;
	private kycService: KycService;

	constructor(stateService: StateService, smelpoService: SMELPOService, guardService: GuardService,
		applicantService: ApplicantService, kycService: KycService, private idService: IdService,
		private sessionService: SessionService,
		private configurationService: ConfigurationService) {

		super();
		this.stateService = stateService;
		this.smelpoService = smelpoService;
		this.guardService = guardService;
		this.applicantService = applicantService;
		this.kycService = kycService;
	}

	update(c: Case) {
		this.stateService.update(c);
	}

	setCurrentCase(c: Case): Case {
		if (this.stateService.state.case !== c) {
			this.stateService.state.case = c;
			this.stateService.update(this.stateService.state);
		}
		return this.stateService.state.case;
	}

	getCurrentCase(): Case | undefined {
		return this.stateService.state.case;
	}

	hasOngoingCase(cases: Case[]): boolean {
		let r = cases.filter(x => x.status === CaseIdStatus.STARTEDBYAPPLICANT);
		return r.length > 0;
	}

	addNewCase(): Promise<Case> {
		if (!this.stateService.state.cases)
			this.stateService.state.cases = [];
		let cases = this.stateService.state.cases;
		let login = this.sessionService.getLogin();
		return this.addCaseOnClient(login, cases);
	}

	// ensureCustomerIdOnCase = (c:Case, customerId:string):void => {
	// 	let customer = this.getCustomerByCustomerId(c, customerId);
	// 	if( !customer ) {
	// 		this.addNewCustomerToCase(c, customerId);
	// 	}
	// }

	private addNewCustomerToCase(c: Case, customerId: string): CustomerIdType {
		if (!c.customers)
			c.customers = [];

		let customer = {
			customerId: customerId,
			customerAdded: new Date().toISOString()
		};
		c.customers.push(customer);
		return customer;
	}

	// getCustomerByCustomerId = (c:Case, customerId:string):CustomerIdType|undefined => {
	// 	if( !c.customers )
	// 		return;
	// 	const g = this.guardService;
	// 	const compareCustomerId = g.compareCustomerId;
	// 	let customer = c.customers.find(x => compareCustomerId(x.customerId||'', customerId));
	// 	return customer;
	// }

	private addCaseOnClient(login: Login, cases: Case[]): Promise<Case> {
		let c: Case = {
			id: this.idService.newGuid(),
			number: 0,
			customers: [],
			createdDate: new Date(),
			lastAccessedDate: new Date(),
			lpCaseId: "",
			status: CaseIdStatus.STARTEDBYAPPLICANT,
			kycApplicantsStatus: [], 
			applicants: [{
				id: this.idService.newGuid(),
				stakeholderType: StakeholderType.SOKANDE,
				customerId: login.ssn,
				firstName: login.firstName,
				lastName: login.lastName
			}]
		}
		this.addNewCustomerToCase(c, login.ssn);
		this.addCaseToStateCases(c);
		return Promise.resolve(c);
	}

	// Checks if case originates from database.
	isServerCase(c: Case): boolean {
		return c.number ? true : false;
	}

	private addCaseOnServer(cases: Case[]): Promise<Case> {
		if (!this.stateService.state.session || !this.stateService.state.session.login)
			throw new Error("No session and login.");

		let login = this.stateService.state.session.login;
		const customerId = login.ssn;

		// Create process on server.
		let processId = this.idService.newGuid();
		let promise = this.smelpoService.addProcess({
			processId: processId,
		}, customerId).then(result => {
			// Attach process from server as case on client. 
			const process = result.data;
			const processId = process.processId;
			return this.loadCase(processId);
		});

		return promise;
	}

	canDeleteCase(c: Case): boolean {
		return c.status && c.status === CaseIdStatus.STARTEDBYAPPLICANT ? true : false;
	}

	deleteCase(cases: Case[], c: Case): Promise<Case> {
		if (this.isServerCase(c)) {
			return this.smelpoService
				.deleteCase(c.id, c.lpCaseId || '', UpdateProcessStatusCaseIdStatusEnum.CLOSEDBYAPPLICANT)
				.then(result => {
					this.removeCaseFromCases(cases, c);
					return c;
				});
		} else {
			this.removeCaseFromCases(cases, c);
			return Promise.resolve(c);
		}
	}

	private removeCaseFromCases(cases: Case[], c: Case): void {
		this.stateService.listRemove(cases, c);
		this.stateService.updateState();
	}

	validateCase(c: Case): ValidationResult {
		let vr = new ValidationResult();
		if (c.applicants) {
			c.applicants.forEach(applicant => {
				vr.addResult(this.applicantService.validateApplicant(applicant));
			});
		}
		return vr;
	}

	sendInApplication(c: Case): Promise<Case> {
		return this.smelpoService
			.setCaseStatus(c.id, c.lpCaseId || '', UpdateProcessStatusCaseIdStatusEnum.READYFOROFFICER)
			.then(x => {
				c.status = CaseIdStatus.READYFOROFFICER;
				this.stateService.update(c);
				return c;
			});
	}

	private saveCaseToServer(c: Case): Promise<Case> {
		let process = SmeModelConverter.toFullProcess(c);
		return this.smelpoService
			.saveProcess(process)
			.then(result => {
				return c;
			});
	}

	private moveDataFromCreatedServerCaseToClientCase(clientCase: Case, serverCase: Case) {
		const cc = clientCase;
		const sc = serverCase;

		cc.id = sc.id;
		cc.number = sc.number;
		cc.status = sc.status;
		cc.createdDate = sc.createdDate;
		cc.lastAccessedDate = sc.lastAccessedDate;
		cc.customers = sc.customers;
		if (cc.applicants && sc.applicants) {
			const cca = cc.applicants[0];
			const csa = sc.applicants[0];
			cca.id = csa.id;
			if (csa.firstName)
				cca.firstName = csa.firstName;
			if (csa.lastName)
				cca.lastName = csa.lastName;
			cca.postalAddress = csa.postalAddress;
			cca.postalCode = csa.postalCode;
			cca.streetAddress = csa.streetAddress;
		}

		this.update(cc);
	}

	saveCase(c: Case): Promise<Case> {
		let promise: Promise<Case>;
		if (!this.isServerCase(c)) {
			// Create case on server and then save.
			if (!this.stateService.state.cases)
				this.stateService.state.cases = [];
			promise = this.addCaseOnServer(this.stateService.state.cases)
				.then(result => {
					// Merge data from created case to client case.
					this.moveDataFromCreatedServerCaseToClientCase(c, result);
					// Perform proper save.
					return this.saveCaseToServer(c);
				}).then(result => {
					// Return original client case but with updated data.
					return c;
				})
		} else {
			// Case already exists on server. Just save. 
			promise = this.saveCaseToServer(c);
		}

		// After saving, always do this.
		return promise.then((result: Case) => {
			this.setApplicantsApprovalAsSavedToDB(c.applicants)
			return result;
		});
	}

	setApplicantsApprovalAsSavedToDB(applicants: Applicant[] | undefined) {
		if (!applicants)
			return;
		applicants.forEach(x => {
			if (x.kyc) {
				const kyc = x.kyc;
				kyc.approvedCreditCheckDB = kyc.approvedCreditCheck;
				kyc.approvedInfoHandlingDB = kyc.approvedInfoHandling;
				kyc.approvedInfoSharingDB = kyc.approvedInfoSharing;
			}
		})
	}

	canSaveCase(c: Case): boolean {
		const hasApproval = this.applicantService.allApplicantsHaveGivenFullApproval(c);
		return hasApproval;
	}

	private later<T>(delay: number, value: T): Promise<T> {
		return new Promise(function (resolve) {
			setTimeout(function () {
				resolve(value);
			}, delay);
		});
	}

	private loadCase = async (processId: string): Promise<Case> => {
		const x = await this.smelpoService.getProcess(processId);
        let linksAll = x.data;
        let c = LpoModelConverter.linksAllToCase(linksAll);

        if (this.attachmentService)
            this.attachmentService.ensureCorrectAttachmentRequests(c);

        return c;
	}

	private addCaseToStateCases = (c: Case) => {
		const state = this.stateService.state;
		if (!state.cases) {
			state.cases = [c];
		} else {
			state.cases.push(c);
		}
		this.stateService.update(state.cases);
		this.stateService.updateState();
	}

	private addCasesToStateCases = (cases: Case[]) => {
		const state = this.stateService.state;
		if (cases && cases.length > 0) {
			cases.sort((a, b) => {
				if (a.number < b.number)
					return -1;
				else
					return 1;
			})
			if (!state.cases) {
				state.cases = cases;
			} else {
				state.cases.length = 0;
				state.cases.splice(0, 0, ...cases);
			}
			this.stateService.update(state.cases);
			this.stateService.updateState();
		}
	}

	loadCases = (): Promise<Case[]> => {
		return this.getMyCases().then(cases => {
			this.addCasesToStateCases(cases);
			return cases;
		})
	}

	appendKycStatusToCases = async (cases: Case[]): Promise<Case[]> => {
		await Promise.all(cases.map(async (c) => {
			if (this.kycService) {
				const x = await this.kycService.getKycForCase(c);
				c.kycApplicantsStatus = x.applicants;
			}
		}));
		
		this.stateService.update(cases);
		this.stateService.updateState();
		
		return cases;
	};

	private getMyCases = (): Promise<Case[]> => {
		if (this.stateService.state.session && this.stateService.state.session.login) {
			let mySSN = this.stateService.state.session.login.ssn;

			var promise = new Promise((resolve, reject) => {

				this.smelpoService.getProcesses(mySSN)
					.then(x => {
						if (x.data.processes) {
							let cases: Case[] = [];
							let fails = 0;
							let processes = x.data.processes || [];
							processes.forEach(process => {
								this.loadCase(process.processId)
									.then(c => {
										if(c.status === "STARTEDBYAPPLICANT" || c.status === "READYFOROFFICER" && !c.lpCaseId) {
											if(c.applicants) {
												for(let i = 0; i < c.applicants.length; i++) {
													if(c.applicants[i].kyc)	{
														c.applicants[i].kyc!.isAmericanCitizen = undefined;
														c.applicants[i].kyc!.isCrsTaxObligated = undefined;
														c.applicants[i].kyc!.isPep = undefined;
														c.applicants[i].kyc!.isPepRelated = undefined;
													}										
												}
											}
										}
										cases.push(c);
										if (fails + cases.length === processes.length)
											resolve(cases);
									}, () => {
										fails++;
										if (fails + cases.length === processes.length) {
											if (cases.length > 0)
												resolve(cases);
											else
												reject(cases);
										}
									})
							})
							return cases;
						}
						return [];
					});

			});

			return (promise as Promise<Case[]>);
		}
		else {
			return Promise.resolve([]);
		}
	}

	// === ADMIN =======


	adminDeleteCase(cases: Case[], c: Case): Promise<Case> {
		return this.smelpoService
			.deleteCase(c.id, c.lpCaseId || '', UpdateProcessStatusCaseIdStatusEnum.CLOSEDBYTHINNING)
			.then(result => {
				this.stateService.listRemove(cases, c);
				this.stateService.updateState();
				return c;
			});
	}

	adminMarkCaseAsHandled(c: Case): Promise<Case> {
		return this.smelpoService
			.setCaseStatus(c.id, c.lpCaseId || '', UpdateProcessStatusCaseIdStatusEnum.CLOSEDBYOFFICER)
			.then(result => {
				this.stateService.updateState();
				return c;
			});
	}

	adminSearchCases(search: AdminCasesSearch): Promise<Case[]> {
		return this.smelpoService.adminSearchProcesses(search)
			.then(result => {
				const cases = result.cases.map(x => {
					// UGLY REMAPPING b/c of casing differences.
					//let json = JSON.stringify(x);
					let goodLinksAll = LinksAllFromJSON(x);
					let c = LpoModelConverter.linksAllToCase(goodLinksAll);
					return c;
				});
				return cases;
			})
	}

}
