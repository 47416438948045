/* Polyfills */
//import 'babel-polyfill';
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/app/App/App';

ReactDOM.render(<App />, document.getElementById('root'));
